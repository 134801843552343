import styled from 'styled-components'
import media from "../../theme/breakpoints"

export const ContainerBreakContainer = styled.div`
    position: relative;
    left: calc(-1 * (100vw - 100%) /2);
    width: calc(100vw - 8rem);
    margin: 0 4rem;

    ${media.lg`
        width: auto;
        margin: 0 auto;
        left: auto;
        position: static;
    `}
`

export const InnerContainer = styled.div`
    max-width: 1030px;
	margin: 0 auto;
	box-sizing: border-box;
`
