import React from "react"
import Image from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Divider from "../../components/Divider"
import Layout from "../../components/Layout"
import Meta from "../../components/Meta"
import PostCard from "../../components/PostCard"
import ContainerBreak from "../../components/ContainerBreak"

import TwitterIcon from "../../images/icons/twitter.svg"
import FacebookIcon from "../../images/icons/facebook.svg"
import LinkedinIcon from "../../images/icons/linkedin.svg"

import {
  Title,
  BlogPostCategoryTag,
  BlogPostContainer,
  BlogContent,
  ImageContainer,
  Author,
  ReadMoreTitle,
  RelatedPosts,
  ShareTitle,
  ShareIconsContainer,
} from "./style"

const BlogPostTemplate = ({ pageContext, location }) => {
  const {
    nextThreePosts,
    categories,
    title,
    description,
    image,
    date,
    author,
    body,
    timeToRead
  } = pageContext

  return (
      <Layout location={location} title={title} description={description} size="large" image={image.childImageSharp.fluid.src}>
        <ImageContainer>
          <Image fluid={image.childImageSharp.fluid} />
        </ImageContainer>
        <BlogPostContainer>
          {categories
            .sort((a, b) => a.category_name.localeCompare(b.category_name))
            .map(category => (
              <BlogPostCategoryTag
                key={category.id}
                to={`/category/${category.slug}`}
              >
                {category.category_name}
              </BlogPostCategoryTag>
            ))}
          <Title>{title}</Title>
          <Author>{author}</Author>
          <Meta date={date} timeToRead={timeToRead} />
          <BlogContent>
            <MDXRenderer>{body}</MDXRenderer>
          </BlogContent>
        </BlogPostContainer>
        <Divider />
        <ShareTitle>Share this post</ShareTitle>
        <ShareIconsContainer>
          <a
            href={`https://twitter.com/intent/tweet?text=${title}&url=${location.href}`}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href={`http://www.facebook.com/share.php?u=${location.href}&title=${title}`}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href={`http://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=${title}`}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinIcon />
          </a>
        </ShareIconsContainer>
        <ContainerBreak>
        <ReadMoreTitle>Read more posts</ReadMoreTitle>
        <RelatedPosts>
          {nextThreePosts.map(post => {
            const data = {
              ...post.node,
              ...post.node.frontmatter,
              categories: post.node.frontmatter.categories,
              frontmatter: undefined
            }
            return <PostCard key={data.id} data={data} />
          })}
        </RelatedPosts>
      </ContainerBreak>
    </Layout>
  )
}

export default BlogPostTemplate
