import React from 'react'

import { ContainerBreakContainer, InnerContainer } from './style'

const ContainerBreak = props => {
	const { children, ...otherProps } = props

	return (
		<ContainerBreakContainer>
			<InnerContainer {...otherProps}>{children}</InnerContainer>
		</ContainerBreakContainer>
	)
}

export default ContainerBreak
