import styled from "styled-components"
import CategoryTag from "../../components/CategoryTag"
import media from "../../theme/breakpoints"

export const BlogPostContainer = styled.div`
  max-width: 71.5rem;
  margin: 5rem auto;
`

export const Title = styled.h1`
  font-size: 6.4rem;
  margin: 0;
  line-height: 6.4rem;
  margin-top: 0.7rem;
  margin-bottom: 3rem;

  ${media.md`
    font-size: 5.6rem;
    line-height: 1;
  `}

  ${media.sm`
    font-size: 4.2rem;
  `}
`

export const BlogPostCategoryTag = styled(CategoryTag)`
  z-index: 1;
  position: relative;

  & + & {
    margin-left: 1rem;
  }
`

export const BlogContent = styled.div`
  font-family: ${props => props.theme.fontFamily.text};
  font-size: 2rem;
  line-height: 1.45;

  ${media.md`
    font-size: 1.8rem;
  `}

  h2 {
    font-size: 2.8rem;
    line-height: 1.35;
  }

  p {
    margin-block-start: 1.25em;
    margin-block-end: 1.25em;
  }

  a {
    color: ${props => props.theme.colors.font.main};
    transition: 0.15s all ease;

    &:hover {
      color: ${props => props.theme.colors.main};
    }
  }

  img {
    max-width: 100%;
  }
`

export const ImageContainer = styled.div`
  max-height: 60rem;
  overflow: hidden;
  margin-top: 5rem;

  ${media.md`
    margin-top: 2rem;
  `}
`

export const Author = styled.p`
  color: ${props => props.theme.colors.font.secondary};
  font-weight: bold;
  margin: 0.5rem 0;
  letter-spacing: 0.5px;
`

export const RelatedPosts = styled.div`
  display: grid;
  grid-gap: 2.8rem;
  grid-template-columns: repeat(3, 1fr);

  ${media.lg`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    & > div:last-of-type {
      display: none;
    }
  `}

  ${media.sm`
    grid-template-columns: repeat(1, 1fr);

    & > div:last-of-type {
      display: block;
    }
  `}
`

export const ReadMoreTitle = styled.h4`
  font-weight: 700;
  margin-bottom: 2rem;
  margin-top: 6rem;
`

export const ShareTitle = styled(ReadMoreTitle)`
  text-align: center;
`

export const ShareIconsContainer = styled.div`
  text-align: center;

  a:hover {
    svg > path {
      fill: ${props => props.theme.colors.hover};
    }
  }

  a + a {
    margin-left: 3.5rem;
  }
`
